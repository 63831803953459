import {ActionTree} from 'vuex';
import axios from '../../utils/axios'
import {OpportunitiesState} from './types';
import {RootState} from '../types';
import {Opportunity} from "@/types/Opportunity";

export const actions: ActionTree<OpportunitiesState, RootState> = {
    getOpportunities({commit}) {
        return new Promise((resolve, reject) => {
            axios.get(`${process.env.VUE_APP_API_URL}/api/projects`)
                .then(resp => {
                    commit('set_opportunities', resp.data);

                    resolve(resp);
                })
                .catch(err => {
                    reject(err);
                });
        });
    },
    getStatuses({commit}) {
        return new Promise((resolve, reject) => {
            axios.get(`${process.env.VUE_APP_API_URL}/api/capsule/statuses`)
                .then(resp => {
                    commit('set_statuses', resp.data);
                    resolve(resp);
                })
                .catch(err => {
                    reject(err)
                })
        })
    },
    getMilestones({commit}) {
        return new Promise((resolve, reject) => {
            axios.get(`${process.env.VUE_APP_API_URL}/api/capsule/milestones`)
                .then(resp => {
                    commit('set_milestones', resp.data);
                    resolve(resp);
                })
                .catch(err => {
                    reject(err)
                })
        })
    },
    updateOpportunity( { commit }, { id, actualRemainingHours } ) {
        return new Promise( ( resolve, reject ) => {
            axios.patch( `${process.env.VUE_APP_API_URL}/api/projects/${id}`, {
                actual_remaining_hours: actualRemainingHours
            } )
                .then( resp => {
                    commit( 'set_opportunity', resp.data as Opportunity )
                    resolve( resp );
                } )
                .catch( err => {
                    reject( err );
                } );
        } );
    },
    updateOpportunityStatus(context, params) {
        return new Promise((resolve, reject) => {
            axios.patch(`${process.env.VUE_APP_API_URL}/api/projects/${params.projectId}/status`, {capsule_status_id: params.statusId})
                .then(resp => {
                    resolve(resp);
                })
                .catch(err => {
                    reject(err);
                });
        });
    },
    updateOpportunityMilestone(context, params) {
        return new Promise((resolve, reject) => {
            axios.patch(`${process.env.VUE_APP_API_URL}/api/projects/${params.projectId}/milestone`, {milestone_id: params.milestoneId})
                .then(resp => {
                    resolve(resp);
                })
                .catch(err => {
                    reject(err);
                });
        });
    },
};
