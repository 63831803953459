import {createApp, VNode} from "vue";
import {ModalOptions} from "@/types/ModalOptions";
import BaseModal from "@/components/BaseModal.vue";
import mitt from "mitt";

export const modal = {
    show(message: string | Array<VNode>, options: ModalOptions) {
        const m = createApp(BaseModal, {
            ...options,
            message: message,
        })

        return new Promise((resolve, reject) => {
            const emitter = mitt()
            emitter.on('ok', value => {
                resolve(value)
                m.unmount()
            });
            emitter.on('cancel', value => {
                reject(value)
                m.unmount()
            });

            m.provide('emitter', emitter)

            const div = document.createElement('div')
            document.body.appendChild(div)
            m.mount(div);
        })
    },
}
