import {createApp} from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import {modal} from './utils/modal';

const app = createApp(App)
    .use(store)
    .use(router)
    .provide('modal', modal)

app.mount('#app')
