import crypto from 'crypto-js';
import qs from "qs";

// eslint-disable-next-line
const base64Url = (string: any) => {
    return string.toString(crypto.enc.Base64)
        .replace(/\+/g, '-')
        .replace(/\//g, '_')
        .replace(/=/g, '');
}

export function createRandomString(num: number) {
    return [...Array(num)].map(() => Math.random().toString(36)[2]).join('')
}

export function generateCodeChallenge(codeVerifier: string): string {
    return base64Url(crypto.SHA256(codeVerifier))
}

export function authRedirect(): void {
    const verifier = createRandomString(128);
    const state = createRandomString(40);
    const challenge = generateCodeChallenge(verifier)

    localStorage.setItem('codeVerifier', verifier);
    localStorage.setItem('state', state);

    const queryString = {
        client_id: process.env.VUE_APP_OAUTH_CLIENT_ID,
        'redirect_uri': `${process.env.VUE_APP_BASE_URL}/auth/callback`,
        'response_type': 'code',
        code_challenge_method: 'S256',
        code_challenge: challenge,
        state: state,
    }

    window.location.href = `${process.env.VUE_APP_API_URL}/oauth/authorize?` + qs.stringify(queryString);
}
