import {Module} from 'vuex';
import {AuthState} from "@/store/auth/types";
import {RootState} from "@/store/types";
import {actions} from "@/store/auth/actions";
import {mutations} from "@/store/auth/mutations";
import {getters} from "@/store/auth/getters";

export const state: AuthState = {
    isLoggedIn: false,
    user: undefined,
    accessToken: undefined,
    refreshToken: undefined,
}

const namespaced = true;

export const auth: Module<AuthState, RootState> = {
    namespaced,
    state,
    actions,
    mutations,
    getters
};
