import {createRouter, createWebHistory, RouteRecordRaw} from 'vue-router'
import {authRedirect} from "@/utils/auth";
import store from "@/store";

const routes: Array<RouteRecordRaw> = [
    {
        path: '/',
        redirect: {
            name: 'KanbanBoard',
        },
    },
    {
        path: '/kanban-board',
        name: 'KanbanBoard',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "kanbanBoardView" */ '../views/KanbanBoardView.vue'),
        meta: {
            requiresAuth: true,
            title: 'Projects board'
        }
    },
    {
        path: '/login',
        name: 'login',
        component: () => import(/* webpackChunkName: "authCallback" */ '../views/AuthCallback.vue'),
        beforeEnter(to) {
            if (typeof to.query['redirect'] === "string")
                window.localStorage.setItem('redirect', to.query['redirect'])
            authRedirect()
        }
    },
    {
        path: '/auth/callback',
        component: () => import(/* webpackChunkName: "authCallback" */ '../views/AuthCallback.vue')
    }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

router.beforeEach((to, from, next) => {
    // instead of having to check every route record with
    // to.matched.some(record => record.meta.requiresAuth)

    if (to.meta.requiresAuth && !store.getters["auth/isLoggedIn"]) {
        store.dispatch('auth/getUserInfo')
            .then(() => {
                next();
            })
            .catch(() => {
                next({
                    name: 'login',
                    query: {redirect: to.fullPath},
                });
            });
    } else {
        next()
    }
})

router.beforeEach((toRoute, fromRoute, next) => {
    // todo window.document.title = toRoute.meta && toRoute.meta.title ? toRoute.meta.title : 'Home';

    next();
})

export default router
