import {GetterTree} from 'vuex';
import {AuthState} from './types';
import {RootState} from '../types';
import {User} from "@/types/User";

export type Getters = {
    user(state: AuthState): User | undefined,
    email(state: AuthState): string,
    isLoggedIn(state: AuthState):  boolean,
}

export const getters: GetterTree<AuthState, RootState> & Getters = {
    user: (state) => {
        return state.user;
    },
    name: (state) => {
        return state.user && state.user.name ? state.user.name : '';
    },
    email: (state) => {
        return state.user && state.user.email ? state.user.email : '';
    },
    isLoggedIn: (state) => {
        return state.isLoggedIn;
    },
    accessToken(state) {
        return state.accessToken && state.accessToken.access_token;
    }
};
