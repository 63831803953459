import {OpportunitiesState} from "@/store/opportunities/types";
import {Module} from 'vuex';
import {RootState} from "@/store/types";
import {actions} from "@/store/opportunities/actions";
import {mutations} from "@/store/opportunities/mutations";
import {getters} from "@/store/opportunities/getters";

export const state: OpportunitiesState = {
    opportunities: [],
    statuses: [],
    milestones: [],
}

const namespaced = true;

export const opportunities: Module<OpportunitiesState, RootState> = {
    namespaced,
    state,
    getters,
    actions,
    mutations,
};
