import {MutationTree} from 'vuex';
import {OpportunitiesState} from './types';
import {Opportunity} from "@/types/Opportunity";

export const mutations: MutationTree<OpportunitiesState> = {
    set_opportunities(state, data) {
        state.opportunities = data;
    },
    set_statuses(state, data) {
        state.statuses = data;
    },
    set_opportunity_status(state, data) {
        const opportunity = state.opportunities.find(p => p.capsule_project_id === data.projectId)

        if (!opportunity) {
            return;
        }

        opportunity.capsule_status_id = data.draggedToStatusId;
        opportunity.milestone_id = data.milestoneId;
    },
    set_milestones(state, data) {
        state.milestones = data;
    },
    set_opportunity(state, data) {
        const opportunity = state.opportunities.find(p => Number(p.capsule_project_id) === Number(data.id));
        if (!opportunity) {
            return;
        }

        opportunity.actual_remaining_hours = data.actual_remaining_hours;
    },
    set_opportunity_milestone(state, data) {
        const opportunity = state.opportunities.find(p => p.capsule_project_id === data.projectId)
        if (!opportunity) {
            return;
        }
        opportunity.milestone_id = data.draggedToId;
    },
};
