import {ActionTree} from 'vuex';
import axios from '../../utils/axios'
import {AuthState} from './types';
import {RootState} from '../types';

export const actions: ActionTree<AuthState, RootState> = {
    getAccessToken({commit, dispatch}, {code, verifier}) {
        return new Promise((resolve, reject) => {
            const query = {
                'grant_type': 'authorization_code',
                'client_id': process.env.VUE_APP_OAUTH_CLIENT_ID,
                'redirect_uri': `${process.env.VUE_APP_BASE_URL}/auth/callback`,
                'code_verifier': verifier,
                'code': code,
            }

            axios.post(`${process.env.VUE_APP_API_URL}/oauth/token`, query)
                .then(response => {
                    commit('set_access_token', response.data);
                    dispatch('getUserInfo')
                    commit('login');
                    resolve(response);
                })
                .catch(err => {
                    reject(err);
                })
        });
    },
    getUserInfo({commit, state}) {
        return new Promise((resolve, reject) => {
            if (!state.accessToken || !state.accessToken.access_token) {
                reject();
                return;
            }

            axios.get(`${process.env.VUE_APP_API_URL}/api/auth/user`)
                .then(response => {
                    commit('set_user', response.data);
                    resolve(response.data);
                })
                .catch(err => {
                    reject(err);
                })
        })
    }
};
