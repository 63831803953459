<template>
    <nav class="flex items-center justify-between flex-wrap bg-orange-300 p-6">
        <div class="flex items-center flex-shrink-0 text-white mr-6">
            <router-link to="/">
                <img class="w-36" src="../assets/logo-jannesmannes-wit.svg"/>
            </router-link>
        </div>
        <div class="w-full block flex-grow lg:flex lg:items-center lg:w-auto">
            <router-link :to="{ name: 'KanbanBoard' }">Projects</router-link>
        </div>
        <div class="ml-auto">
            Hoi {{ user }}
        </div>
    </nav>
</template>

<script>
import { computed, defineComponent } from "vue";
import { useStore } from "vuex";

export default defineComponent( {
    setup() {
        const store = useStore();
        return {
            user: computed( () => store.getters["auth/name"] )
        }
    }
} );
</script>
