import {GetterTree} from 'vuex';
import {OpportunitiesState} from './types';
import {RootState} from '../types';
import {Opportunity} from "@/types/Opportunity";
import {Milestone} from "@/types/Milestone";

export type Getters = {
    opportunities(state: OpportunitiesState): Array<Opportunity>,
};

export const getters: GetterTree<OpportunitiesState, RootState> & Getters = {
    opportunities: (state) => {
        return state.opportunities;
    },
    milestone: (state) => (id: number) : Milestone|undefined => {
        return state.milestones.find(m => m.id == id);
    }
};
