import {createStore} from 'vuex'
import {RootState} from "@/store/types";
import {opportunities} from "@/store/opportunities";
import {auth} from "@/store/auth";

export default createStore<RootState>({
    state: (): RootState => ({
        status: '',
    }),
    modules: {
        opportunities,
        auth,
    }
})
