import axios from "axios";
import store from "../store";

const instance = axios.create();

instance.interceptors.request.use(function (config) {
    const accessToken = store.getters['auth/accessToken'];
    // Do something before request is sent
    config.headers = {
        Authorization: `Bearer ${accessToken}`,
    }
    return config;
}, function (error) {
    // Do something with request error
    return Promise.reject(error);
})

export default instance;
