import {MutationTree} from 'vuex';
import {AuthState} from './types';
import {User} from "@/types/User";

export const mutations: MutationTree<AuthState> = {
    set_access_token(state, accessToken) {
        state.accessToken = accessToken;
    },
    set_refresh_token(state, refreshToken) {
        state.refreshToken = refreshToken;
    },
    set_user(state, payload: User) {
        state.user = payload;
    },
    login(state) {
        state.isLoggedIn = true;
    },
    logout(state) {
        state.isLoggedIn = false;
        state.user = undefined;
        state.accessToken = undefined;
        state.refreshToken = undefined;
    }
};
